<template>

  <v-row class="inner-content">
    <v-col>
      <NotificationsStatsHourly />
      <NotificationsStatsDaily class="mt-5" />
      <NotificationsStatsWeekly class="mt-5" />
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: 'NotificationsStats',

  components: {
    NotificationsStatsDaily: () => import('@/components/notifications/NotificationsStatsDaily.vue'),
    NotificationsStatsHourly: () => import('@/components/notifications/NotificationsStatsHourly.vue'),
    NotificationsStatsWeekly: () => import('@/components/notifications/NotificationsStatsWeekly.vue'),
  },
}
</script>
